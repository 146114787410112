import { styled } from "styled-components";


export const Content = styled.div`
    width: 100%;
    padding-top: 150px;
    padding-bottom: 81px;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;


    .content{
        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        gap: 30px;
    }

    .header{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .images{
        max-width: 600px;
        width: 100%;
        border-radius: 3px;
    }

    .image-content{
        height: 300px;
        width: 100%;
        object-fit: contain;
    }

    .card{
        max-width: 400px;
        width: 100%;
        height: 100%;

        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 5px;

        padding: 20px;

        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .title-card{
        font-size: 2rem;
        font-weight: 400;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .divider{
        height: 1px;
        background-color: #e5e5e5;
    }

    .other-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 20px;
    }
    
    .titulo{
        font-size: 1.2rem;
    }

    .localizacao{
        display: flex;
        gap: 10px;
        font-size: 1rem;
        color: #666;
    }

    .contatos{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .contatos button{
        height: 40px;
        background-color: #D4A73A;
        font-size: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        gap: 10px;
    }

    .contatos a{
        text-decoration: none;

        height: 40px;
        background-color: #D4A73A;
        font-size: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        gap: 10px;
        color: white;
        border-radius: 6px;
    }


    .contatos a:hover{
        opacity: 0.8;
    }

    .contatos button:hover{
        background-color: #D4A73A !important;
        opacity: 0.8;
    }

    .dots button{
        background-color: #D4A73A !important;
        height: 5px !important
    }

    .description{
        width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 5px;
        padding: 20px;
        line-height: 30px;
    }

    @media screen and (max-width: 1100px){
        .header{
            flex-direction: column;
            gap: 30px;
            width: 100%;
            justify-content: center;
        }

        .images{
            max-width: 100%;
        }
        
        .card{
            max-width: 100%;
        }
    }
`