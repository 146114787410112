import { styled } from "styled-components";

export const Container = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;


    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 30px 30px;


    .filtros{
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        padding: 10px;

        display: flex;
        flex-direction: column;
        gap: 10px;
    }


    .buttons{
        display: flex;
        gap: 15px;
    }
    

`