import { styled } from "styled-components";

export const Content = styled.div`
     width: 100%;
    padding-top: 150px;
    padding-bottom: 81px;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .content{
        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        gap: 30px;

        font-size: 2.5rem;
    }

    .button button{
        width: 100%;
        max-width: 300px;
        height: 50px;
        background-color: #D4A73A;
        font-size: 1.3rem;
    }

    .button button:hover{
        background-color: #D4A73A !important;
        opacity: 0.8;
    }

`