import { styled } from "styled-components";


export const Cadastro = styled.div`
    width: 100%;

    color: black;

    display: flex;
    justify-content: center;
    
    .content{
        width: 100%;

        display: flex;
        flex-direction: column;
    }

    .title{
        font-size: 1.8rem;
        font-weight: 400;
        width: 100%;

        padding: 25px;
        border-bottom: 2px solid #E3E3E5;
    }

    .content-children{
        width: 100%;
        padding: 25px;
    }
`