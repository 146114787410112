import { styled } from "styled-components";

export const Endereco = styled.section`
    width: 100%;
    

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;

    .content{
        width: 90%;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 100px 0px;

        gap: 150px;
    }

    .icons-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }

    .icon{
        font-size: 4rem;
        color: #D4A73A;
    }

    .title{
        font-size: 1.125rem;
        color: #333333;
        font-weight: 400;
    }

    .subtitle{
        text-align: center;
        font-size: 0.875rem;
        color: #999999;
        line-height: 20px;
    }

    @media (max-width: 820px){
        .content{
            flex-direction: column; 
            gap: 50px;
        }

        .icon{
            font-size: 3rem;
        }
    }
`