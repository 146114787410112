import { styled } from "styled-components";
import bg from '../../assets/voragoassets/capaFacebook.jpg'

export const AdminDetails = styled.div`
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    z-index: 1;
    padding: 10px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(51, 51, 51, 1);
        opacity: .6; 
    }

    .container{
        width: 100%;

        max-width: 500px;

        background-color: #2B2E47;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

        padding: 20px;
        border-radius: 10px;
        z-index: 2;
    }

    .container label{
        color: white;
    }

    .logo{
        text-align: center;
    }

    .logo img{
        width: 150px;
        
    }

    button{
        background-color: #D4A73A;
        width: 100px;
        height: 40px;
    }

    button:hover{
        background-color: #D4A73A !important;
        opacity: 0.7;
    }

    .ant-checkbox-inner{
        background-color: #D4A73A;
        border-color: #D4A73A;
    }

    .error p{
        color: white;
        padding: 10px 10px;
        background-color: red;
        border-radius: 10px;
        margin: 10px 0px;
    }
`