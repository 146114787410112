import { styled } from "styled-components";


export const Container = styled.div`
    width: 100%;

    display: flex;
    position: relative;
    
    background-color: #F8F9FA;
`

export const Header = styled.header`
    width: 250px;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: fixed;
    left: 0;
    top: 0;

    background-color: #313347;


    .header{
        width: 100%;
        height: 100px;
        display: flex;
        
        align-items: center;
        justify-content: center;

        
        background-color: #2B2E47;
        padding: 10px;
    }

    .logo{
        width: 100%;
        margin-right: 7px;
    }

    .divider-container{
        width: 100%;
    }

    .divider{
        background-color: rgba(5, 5, 5, 0.2);
    }

    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        padding: 30px 35px;
        
    }

    a{
        text-decoration: none;
        display: flex;

        gap: 30px;
        font-size: 1.2rem;

        color: #e9e9e9;

        cursor: pointer;
    }

    .nav p{
       
    }

    a:hover{
        color: #D4A73A;
    }

    .active{
        color: #D4A73A !important;
    }

    .footer{
        width: 100%;

        display: flex;
        align-items: center;

        position: absolute;

        bottom: 50px;
        padding: 0px 35px;

        font-size: 1.2rem;

        gap: 8px;

        .span{
            display: flex;
            gap: 20px;
            cursor: pointer;   
            color: #e9e9e9; 
            text-decoration: none;
        }  

        .span:hover{
            color: #D4A73A;
        }
    }
`


export const HeaderMobile = styled.header`
    width: 100%;

    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);

    padding: 15px 30px;

    .header{
        width: 100%;
        height: 100px;
        display: flex;
        
        align-items: center;
        justify-content: center;

        
        background-color: #2B2E47;
        padding: 10px;
    }

    .header-card{
        width: 250px;
        height: 100vh;

        display: flex;
        flex-direction: column;
        align-items: center;

        position: fixed;
        left: 0;
        top: 0;

        background-color: #313347;

        z-index: 999;

    }

    .logo{
        width: 100%;
        margin-right: 7px;
    }

    .divider-container{
        width: 100%;
    }

    .divider{
        background-color: rgba(5, 5, 5, 0.2);
    }

    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;

        padding: 30px 35px;
        
    }

    a{
        text-decoration: none;
        display: flex;

        gap: 30px;
        font-size: 1.2rem;

        color: #e9e9e9;

        cursor: pointer;
    }

    .nav p{
       
    }

    a:hover{
        color: #D4A73A;
    }

    .active{
        color: #D4A73A !important;
    }

    .footer{
        width: 100%;

        display: flex;
        align-items: center;

        position: absolute;

        bottom: 50px;
        padding: 0px 35px;

        font-size: 1.2rem;

        gap: 8px;

        .span{
            display: flex;
            gap: 20px;
            cursor: pointer;   
            color: #e9e9e9; 
            text-decoration: none;
        }  

        .span:hover{
            color: #D4A73A;
        }
    }


`