import { ProFormUploadButton } from '@ant-design/pro-components';
import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export const UploadCustom = ({ record, setFileList, fileList }) => {

    const DraggableUploadListItem = ({ originNode, file }) => {
        const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
            id: file.uid,
        });
        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            cursor: 'move',
        };
        return (
            <div
                ref={setNodeRef}
                style={style}
                // prevent preview event when drag end
                className={isDragging ? 'is-dragging' : ''}
                {...attributes}
                {...listeners}
            >
                {/* hide error tooltip when dragging */}
                {file.status === 'error' && isDragging ? originNode.props.children : originNode}
            </div>
        );
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    // Converter a lista de URLs para o formato esperado pelo componente Upload
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    useEffect(() => {
        setFileList(record.imagesUrl.map((url, index) => ({
            uid: `${index}`,
            name: `image_${index}.png`,
            status: 'done',
            url: url,
        })))
    }, [])

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleCancel = () => setPreviewOpen(false);

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setFileList((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id);
                const overIndex = prev.findIndex((i) => i.uid === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    });

    return (
        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
            <SortableContext items={fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                <ProFormUploadButton
                    title="Imagens"
                    listType="picture-card"// Opcional: Se você não deseja mostrar a lista de uploads, pode definir como false.
                    fileList={fileList}
                    fieldProps={{
                        beforeUpload: (e) => {
                            setFileList([...fileList, e]);
                            return false;
                        },
                        action: (e) => {
                            console.log("action");
                        },
                        onPreview: handlePreview,
                        onRemove: (file) => {
                            const index = fileList.indexOf(file);
                            const newFileList = fileList.slice();
                            newFileList.splice(index, 1);
                            setFileList(newFileList);
                        },
                        itemRender: (originNode, file) => (
                            <DraggableUploadListItem originNode={originNode} file={file} />
                        ),
                    }}
                />
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
            </SortableContext>
        </DndContext>
    );
};