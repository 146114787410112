import { styled } from "styled-components";


export const Content = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;

    .content{
        max-width: 1000px;
        width: 100%;
        padding: 20px;

        margin: 50px 0px;
        gap: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;

        
    }

    .title{
        font-size: 2.5rem;
        color: #222222;
    }

    .form-card{
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 20px;
        border-radius: 6px;
    }

    form{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .row{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .row label{
        font-size: 1.2rem;
    }

    .input{
        padding: 20px 20px;
        background-color: #EBEBEB;
        border: none;

        border-radius: 6px;
    }

    .row textarea{
        padding: 10px 20px;
        resize: none;
        height: 150px;
        background-color: #EBEBEB;
        border: none;

        border-radius: 6px;
    }

    .submit{
        width: 150px;
        height: 50px;
        border-radius: 6px;

        background-color: #D4A73A;

        color: white;

        border: none;

        cursor: pointer;
    }

    .submit:hover{
        opacity: 0.8;
    }
`