import { styled } from "styled-components";

export const Card = styled.div`
    max-width: 350px;
    width: 100%;
    padding: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    border-radius: 5px;

    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

    cursor: pointer;

    transition: all .2s ease-in-out; 

    &&:hover{
        transform: translateY(-6px);
    }

    .imagem{
        width: 100%;

    }
   
    .imagem img{
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

    .categoria-card{
        font-size: 1.2rem;
        font-weight: 600;
    }

    .preco-card{
        font-size: 1.5rem;
        font-weight: 600;
    }


    .title-card{
        color: #333333;
    }

    .local{
        font-weight: bold;
    }
`