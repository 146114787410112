import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute = ({component: component, ...rest}) => {
    const auth = localStorage.getItem("userToken");
    return auth ? <Outlet/> : <Navigate to="/admin"/>
};

export const IsAuthRoute = ({component: component, ...rest}) => {
    const auth = localStorage.getItem("userToken");
    return auth ? <Navigate to="/admin/dashboard"/>  : <Outlet/>
}