import React, { useState, useEffect } from "react";

import { Image, Table, Tag, Space, Modal, Button, Form, message, } from 'antd';
import { ModalForm, ProForm, ProFormSelect, ProFormText, ProFormTextArea, ProFormDigit } from '@ant-design/pro-components';
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../lib/client";

import { UploadCustom } from "./proUploadCustom";

import anuncioService from "../services/anuncio.service";


export const AnunciosContent = () => {


    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [dataModal, setDataModal] = useState({});
    const [fileList, setFileList] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataFetch = await anuncioService.getAnuncios();
                setData(dataFetch)
            } catch (error) {
                console.error("Error getting room data:", error);
            }
        }

        fetchData();
        const unsubscribe = anuncioService.subscribeAnuncios((anuncios) => {
            setData(anuncios)
        });

        return () => {
            unsubscribe();
        }

    }, []);

    if (!data) {
        return null;
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const criarNovoItem = async (data) => {
        anuncioService.updateAnuncio(data);

        messageApi.open({
            key,
            type: 'success',
            content: 'Anúncio editado',
            duration: 2,
        });
    };


    const handleFinish = async (id, values) => {
        messageApi.open({
            key,
            type: 'loading',
            content: 'Criando anúncio',
        });
        try {
            const uploadPromises = fileList.map(async (file) => {

                return new Promise((resolve, reject) => {
                    if (!file.status) {
                        const uniqueFileName = uuidv4() + "-" + file.name;
                        const fileRef = ref(storage, `images/${uniqueFileName}`);
                        const uploadTask = uploadBytesResumable(fileRef, file);
                        uploadTask.on(
                            "state_changed",
                            null,
                            (error) => reject(error),
                            async () => {
                                try {
                                    const url = await getDownloadURL(uploadTask.snapshot.ref);
                                    resolve(url);
                                } catch (error) {
                                    reject(error);
                                }
                            }
                        );
                    } else {
                        resolve(file.url);
                    }
                });
            });

            const resolvedUrls = await Promise.all(uploadPromises);

            const newData = {
                id: id,
                titulo: values.titulo,
                preco: values.preco,
                descricao: values.descricao,
                categoria: values.categorias,
                localizacao: values.localizacao,
                imagesUrl: resolvedUrls
            };

            await criarNovoItem(newData);

        } catch (err) {
            console.log(err);
            messageApi.open({
                key,
                type: 'error',
                content: 'Anúncio falhou em ser criado',
                duration: 2,
            });
        }
    }

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const formatCurrency = (value) => {
        return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    const showModal = (data) => {
        setOpen(true);
        setDataModal(data);
    };

    const handleOk = async () => {
        setConfirmLoading(true);
        const deleteDoc = await anuncioService.deleteAnuncio(dataModal);

        if (deleteDoc === true) {
            setConfirmLoading(false);
            setOpen(false);
            message.success("Anúncio deletado com sucesso!");
        } else {
            setConfirmLoading(false);
            setOpen(false);
            message.error("Anúncio falhou em deletar");
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Table dataSource={data} style={{ width: "100%" }} onChange={onChange}>
                <Table.Column
                    title="Imagem"
                    dataIndex="imagesUrl"
                    key="imagesUrl"
                    responsive={["lg"]}
                    render={(images) => (
                        <Image src={images[0]} />
                    )}

                />
                <Table.Column title="Titulo" dataIndex="titulo" key="titulo" />
                <Table.Column
                    title="Preço"
                    dataIndex="preco"
                    key="preco"
                    responsive={["md"]}
                    sorter={
                        (a, b) => Number(b.preco) - Number(a.preco)
                    }
                    render={(price) => (
                        <p>{formatCurrency(price)}</p>
                    )}
                />
                <Table.Column title="Descrição" responsive={["md"]} dataIndex="descricao" key="descricao" ellipsis={true} />
                <Table.Column
                    title="Categorias"
                    dataIndex="categoria"
                    key="categoria"
                    responsive={["sm"]}
                    filters={
                        [
                            {
                                text: 'Apartamento',
                                value: "Apartamento"
                            },
                            {
                                text: "Casa",
                                value: "Casa"
                            },
                            {
                                text: "Terreno",
                                value: 'Terreno'
                            },
                            {
                                text: "Outros",
                                value: 'Outros'
                            }
                        ]
                    }
                    onFilter={(value, record) => record.categoria === value}
                    render={(tag) => (
                        <Tag color="#D4A73A" key={tag}>
                            {tag}
                        </Tag>
                    )}
                />
                <Table.Column
                    title="Ações"
                    key="acoes" 
                    render={(_, record) => (
                        <Space size="middle">
                            {contextHolder}
                            <ModalForm
                                title="Editar"
                                trigger={
                                    <Button type="primary">
                                        Editar
                                    </Button>
                                }
                                form={form}
                                autoFocusFirstInput
                                modalProps={{
                                    destroyOnClose: true,
                                    onCancel: () => console.log('run'),
                                }}
                                submitTimeout={2000}
                                onFinish={(e) => handleFinish(record.id, e)}
                                submitter={{
                                    searchConfig: {
                                        submitText: 'Editar',
                                        resetText: 'Cancelar',
                                    },
                                }}

                            >
                                <ProForm.Group >
                                    <ProFormText
                                        width="m"
                                        name="titulo"
                                        label="Titulo"
                                        initialValue={record.titulo}
                                        placeholder="Digite o titulo aqui..."
                                    />
                                    <ProFormText
                                        width="m"
                                        name="localizacao"
                                        label="Localização"
                                        initialValue={record.localizacao}
                                        placeholder="Digite a localização aqui..."
                                    />
                                </ProForm.Group>
                                <ProForm.Group>
                                    <ProFormDigit
                                        name="preco"
                                        label="Preço"
                                        fieldProps={{
                                            formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                            parser: (value) => value.replace(/\$\s?|(,*)/g, '')
                                        }}
                                        initialValue={record.preco}
                                        placeholder="Digite o preço aqui..."
                                    />
                                    <ProFormSelect
                                        request={async () => [
                                            {
                                                value: 'Apartamento',
                                                label: 'Apartamento',
                                            },
                                            {
                                                value: 'Casa',
                                                label: 'Casa',
                                            },
                                            {
                                                value: 'Terreno',
                                                label: 'Terreno',
                                            },
                                            {
                                                value: 'Outros',
                                                label: 'Outros',
                                            },
                                        ]}
                                        width="md"
                                        name="categorias"
                                        label="Categorias"
                                        initialValue={record.categoria}
                                    />
                                </ProForm.Group>
                                <ProFormTextArea placeholder="Digite a Descrição aqui..." width="100%" name="descricao" label="Descrição" initialValue={record.descricao} />
                                <UploadCustom
                                    record={record}
                                    setFileList={setFileList}
                                    fileList={fileList}
                                />
                            </ModalForm>
                            <a onClick={() => showModal(record)}>Delete</a>
                        </Space>
                    )}
                />
            </Table>
            <Modal
                title="Excluir Anúncio"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <p>Tem certeza que deseja deletar o Anúncio?</p>
            </Modal>
        </>
    )
}