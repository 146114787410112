import { styled } from "styled-components";
import bg from '../../assets/voragoassets/capaFacebook.jpg'


export const Home = styled.div`
    width: 100%;
    height: 450px;

    color: black;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`