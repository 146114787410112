import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { MenuOutlined, InstagramOutlined, FacebookOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Skeleton, FloatButton } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from '../assets/voragoassets/LOGOS/LOGO PNG 2.png';


import { Header, HeaderMobile } from "../styles/defaultLayout";
import { Home } from "../styles/imoveisAVenda/home";
import { Content } from "../styles/obrasMain/content";
import { Footer } from "../styles/footer";

import obrasService from "../services/obras.service";

var settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


function ObrasMain() {
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        document.title = "Vorago";
        const fetchData = async () => {
            try {
                const dataFetch = await obrasService.getObras();
                setData(dataFetch);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                navigate("/404");
            }
        }

        fetchData();

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!data) {
        return null;
    }

    const handleNavigate = (item) => {
        navigate(`/obras/${item.titulo.toLowerCase().replace(/\s/g, '-')}/${item.id}`);
        window.scrollTo(0, 0);
    }

    return (
        <>
            {
                width <= 950 ? (
                    <HeaderMobile>
                        <div className="content">
                            <div className="logo">
                                <img src={logo} />
                            </div >
                            <MenuOutlined className="menu" onClick={() => setOpen(!open)} />
                        </div>
                        <AnimatePresence>
                            {open && (
                                <motion.div
                                    className="drawner"
                                    layout
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "200px", opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    onClick={() => setOpen(false)}
                                >
                                    <div className="container">
                                        <Link to="/">Sobre Nós</Link>
                                        <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                        <Link to="/obras">Obras</Link>
                                        <Link to="/contato">Contato</Link>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </HeaderMobile>
                )
                    : (
                        <Header>
                            <div className="content">
                                <div className="logo">
                                    <Link to="/"><img src={logo} /></Link>
                                </div >
                                <div className="navigation">
                                    <Link to="/">Sobre Nós</Link>
                                    <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                    <Link to="/obras">Obras</Link>
                                    <Link to="/contato">Contato</Link>
                                </div>
                            </div >
                        </Header >
                    )
            }
            <>
                <Home />
                <Content>
                    <div className="content">
                        <div className="title">
                            Obras
                        </div>
                        <div className="content-obra">
                            {loading ? (
                                <Skeleton active />
                            ) : (
                                <>
                                    <div className="obras-andamento">
                                        <div className="title-obras">
                                            Obras em Andamento
                                        </div>
                                        <Slider
                                            {...settings}
                                        >
                                            {data?.filter(filter => filter.categoria === "Obra em Andamento").map((item, key) => {
                                                
                                               return (
                                                    <div className="card" key={key} onClick={() => handleNavigate(item)}>
                                                        <div className="imagem">
                                                            <img src={item.imagesUrl[0]} />
                                                        </div>
                                                        <div className="title-card">
                                                            <p>{item.titulo}</p>
                                                        </div>
                                                        <div className="local">
                                                            <p>{item.localizacao}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                    <div layout className="obras-concluida">
                                        <div className="title-obras">
                                            Obras Concluídas
                                        </div>
                                        <Slider
                                            {...settings}
                                        >
                                            {data?.filter(filter => filter.categoria === "Obra Concluída").map((item, key) => (
                                                <div className="card" key={key} onClick={() => handleNavigate(item)}>
                                                    <div className="imagem">
                                                        <img src={item.imagesUrl[0]} />
                                                    </div>
                                                    <div className="title-card">
                                                        <p>{item.titulo}</p>
                                                    </div>
                                                    <div className="local">
                                                        <p>{item.localizacao}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Content>
                <Footer>
                    <div className="content">
                        <p className="copyright">
                            © Vorago 2023, All Rights Reserved. Powered By <a href="https://nirayuki.netlify.app/" target="_blank">Nirayuki</a>
                        </p>
                        <div className="social">
                            <FacebookOutlined />
                            <a className="insta-a" href="https://instagram.com/construtoravorago" target="_blank"><InstagramOutlined  /></a>
                        </div>
                    </div>
                </Footer>
                <FloatButton type="primary" icon={<WhatsAppOutlined/>} href="https://api.whatsapp.com/send/?phone=45999425066&text&type=phone_number&app_absent=0" target="_blank"/>
            </>
        </>
    )
}


export default ObrasMain;