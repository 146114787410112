import { styled } from "styled-components";
import bg_home from '../assets/voragoassets/capaFacebook.jpg'


export const Home = styled.section`
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-image: url(${bg_home});
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;

    width: 100%;
    height: 100vh;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(51, 51, 51, 1);
        opacity: .6; 
    }

    .content{
        width: 100%;
        z-index: 2;
        position: absolute;
        top: 37%;
        text-align: center;
        

        display: flex;
        flex-direction: column;
        align-items: center;
        
        gap: 30px;

        z-index: 999;
    }

    .title-home{
        width: 100%;
        max-width: 750px;
        font-size: 4.375rem;
        font-weight: 400;
        letter-spacing: 3px;
        margin: 0;

        color: white;
    }

    .subtitle-home{
        color: white;

        font-size: 1.125rem;
        font-weight: 400;
    }

    .button-home{
        width: 170px;
        height: 50px;
        border-radius: 50px;
        background-color: #D4A73A;

        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;

        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

        text-decoration: none;

    }

    .button-home:hover{
        background-color: #D4A73A !important;
        opacity: 0.9;
    }

    @media screen and (max-width: 800px){
        .content{
            padding: 0px 20px;
        }
        .title-home{
            font-size: 2rem;
        }
        .subtitle-home{
            font-size: 1rem;
        }
    }
`