import React from "react";
import { Route, Routes } from "react-router-dom";

import Main from './pages/main';
import Admin from "./pages/admin";
import Dashboard from "./pages/dashboard";
import Anuncios from "./pages/anuncios";
import ImovesAVenda from "./pages/imoveisAVenda";
import Obras from './pages/obras';

import { ProtectedRoute, IsAuthRoute } from "./components/protectedRoute";
import SlugImoveis from "./pages/slugImoveis";
import ObrasMain from "./pages/obrasMain";
import SlugObras from "./pages/slugObras";
import NotFound from "./pages/404";
import Contato from "./pages/contanto";

const Rotas = () => {

    return (
        <Routes>
            <Route path="*" element={<NotFound/>}/>
            <Route path="/404" element={<NotFound/>}/>
            <Route path="/" element={<Main />} />
            <Route path="/contato" element={<Contato/>}/>

            <Route path="/imoveis-a-venda" element={<ImovesAVenda/>}/>
            <Route path="/imoveis-a-venda/:slug/:slug" element={<SlugImoveis/>}/>

            <Route path="/obras" element={<ObrasMain/>}/>
            <Route path="/obras/:slug/:slug" element={<SlugObras/>}/>

            <Route path="/admin" element={<IsAuthRoute />}>
                <Route path="/admin" element={<Admin />} />
            </Route>

            <Route path="/admin/dashboard" element={<ProtectedRoute />}>
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/admin/dashboard/anuncios" element={<Anuncios />} />
                <Route path="/admin/dashboard/obras" element={<Obras />} />
            </Route>
        </Routes>
    )
}

export default Rotas;