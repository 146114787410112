import { styled } from "styled-components";

export const Header = styled.header`
    width: 100%;
    height: 80px;

    position: fixed;

    left:0;
    right:0;

    display: flex;
    align-items: center;
    justify-content: center;


    
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);

    z-index: 999;

    .content{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo img{
        width: 250px;
    }

    .navigation{
        display: flex;
        gap: 50px;
    }

    .navigation a{
        text-decoration: none;
        color: #333333;
        font-size: 1rem;    
        font-weight: 400;
        line-height: 100px;
        letter-spacing: 0.48px;
    }
    

    .navigation a:hover{
        color: #D4A73A;
    }

    .active{
        color: #D4A73A !important;
    }

    @media screen and (max-width: 1140px){
        .content{
            width: 90%;
        }
    }

`

export const HeaderMobile = styled.header`
    width: 100%;
    height: 100px;

    position: fixed;

    left:0;
    right:0;

    font-size: 2rem;
    color: #D4A73A;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);

    .logo img{
        width: 250px;
    }

    .content{
        width: 100%;
        max-width: 750px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menu{
        margin-top: -14px;
        margin-right: 20px;
    }

    .drawner{
        width: 100%;
        height: 200px;
        top: 100px;
        position: absolute;
        background-color: white;

        display: flex;
        justify-content: center;
    }

    .container{
        width: 100%;
        max-width: 750px;
        background-color: #333333;
        padding: 10px 20px;

        display: flex;
        flex-direction: column;
        justify-content: space-between
    }

    a{
        text-decoration: none;
        color: white;
        font-size: 0.75rem;
    }
`