import React, {useEffect} from 'react';
import { useLocation } from 'react-router';
import GlobalStyle from './styles/globalStyles';
import Rotas from './routes';
import { AuthContextProvider } from './lib/authContext';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <AuthContextProvider>
      <ScrollToTop/>
      <GlobalStyle/>
      <Rotas/>
    </AuthContextProvider>
    
  );
}

export default App;
