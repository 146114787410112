import { styled } from "styled-components";


export const Servicos = styled.section`
    width: 100%;
    

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;

    .content{
        width: 90%;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 100px 0px;

        gap: 70px;
    }

    .title-servicos-content{
        width: 90%;
        max-width: 555px;

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 27px;

    }

    .title-servicos{
        font-size: 2.25rem;
        color: #222222;
    }

    .subtitle-servicos{
        color: #999999;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
        line-height: 24px;
    }

    .icons-content{
        width: 1170px;
        display: flex;
        gap: 40px 20px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .icon-content{
        flex-grow: 1;
        flex-basis: 40%;
        width: 212px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        align-items: center;

        gap: 40px;
    }

    .img-icon{
        width: 50px;
        filter: invert(65%) sepia(50%) saturate(533%) hue-rotate(5deg) brightness(94%) contrast(92%);
    }

    .content-icon{
        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 15px;
    }

    .title-icon{
        text-align: center;
        font-size: 1.125rem;
        color: #333333;
    }

    .subtitle-icon{
        color: #999999;
        text-align: center;
        font-weight: 400;
        line-height: 24px;
        font-size: 0.875rem;
        width: 80%;
        text-align: center;
    }

    @media screen and (max-width: 1200px){
        .icons-content{
            width: 970px;
        }
    }

    @media screen and (max-width: 1026px){
        .icons-content{
            width: 80%;
            flex-wrap: wrap;
            
            gap: 30px;
        }

        .icon-content{
            width: 40%;
        }
    }
    
    @media screen and (max-width: 1026px){
        .icons-content{
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            gap: 30px;
        }

        .icon-content{
            width: 100%;
        }

        .subtitle-icon{
            width: 300px;
        }

        .subtitle-servicos{
            width: 90%;
            max-width: 400px;
        }
    }

    @media screen and (max-width: 360px){
        .title-servicos{
            font-size: 1.5rem;
        }
    }
`