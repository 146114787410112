import { styled } from "styled-components";


export const Content = styled.div`
    width: 100%;
    padding-top: 150px;
    padding-bottom: 81px;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;


    .content{
        max-width: 1200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        gap: 50px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 30px 50px;
    }

    .title{
        width: 100%;
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
    }
    

    .descricao{
        line-height: 30px;
        text-align: center;
    }

    .button{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .button a{
        text-decoration: none;
        width: 100%;
        max-width: 300px;

        height: 50px;
        background-color: #D4A73A;
        font-size: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;

        gap: 10px;
        color: white;
        border-radius: 6px;
    }


    .button a:hover{
        opacity: 0.8;
    }

    .images{
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        justify-content: center;
        align-items: center;

        row-gap: 20px;
        column-gap: 50px;
    }

    .images img{
        width: 100%;

        max-width: 500px;
    }
`