import database from "../lib/client";
import {
  addDoc,
  collection,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
  getDoc
} from "firebase/firestore";
import { storage } from "../lib/client";
import { ref, deleteObject } from 'firebase/storage';


const anunciosCollectionRef = collection(database, "anuncios");

const extractFileNameFromUrl = (url) => {
  const regex = /%2F(.+?)\?/; // Expressão regular para extrair o nome do arquivo entre %2F e ?
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  // Caso a expressão regular não encontre uma correspondência válida
  return null;
};
class anunciosService {
  addAnuncio = async (anuncioData) => {
    const docRef = await addDoc(anunciosCollectionRef, anuncioData);
    return docRef.id; // Retorna o ID do novo documento criado
  }

  getAnuncio = async (docId) => {
    const docRef = doc(anunciosCollectionRef, docId)
    const anuncio = await getDoc(docRef);

    return anuncio.data();
  }

  getAnuncios = async () => {
    const anuncios = await getDocs(anunciosCollectionRef);
    const docs = [];

    anuncios.forEach((doc, key) => {
      docs.push({
        id: doc.id,
        titulo: doc.data().titulo,
        preco: doc.data().preco,
        descricao: doc.data().descricao,
        categoria: doc.data().categoria,
        imagesUrl: doc.data().imagesUrl,
        localizacao: doc.data().localizacao
      });
    });

    return docs;
  }


  deleteAnuncio = async (docData) => {
    try {
      console.log(docData.id);
      const docRef = doc(anunciosCollectionRef, docData.id);
      console.log("caindo aq dps")

      await Promise.all(
        docData.imagesUrl.map(async (imageUrl) => {
          // Extrair o nome do arquivo a partir da URL (opcional, dependendo da sua estrutura de armazenamento)
          const fileName = extractFileNameFromUrl(imageUrl);
          const fileRef = ref(storage, `images/${fileName}`);

          await deleteObject(fileRef);
        })
      );
      await deleteDoc(docRef);
      console.log('All operations completed successfully');
      return true;
    } catch (err) {
      console.log('Error:', err);
      return false;
    }
  };

  updateAnuncio = async (docData) => {
    try {
      const docRef = doc(anunciosCollectionRef, docData.id);
      await updateDoc(docRef, docData);
      return true
    } catch (err) {
      return false
    }
  }

  subscribeAnuncios = (callback) => {

    return onSnapshot(anunciosCollectionRef, (querySnapshot) => {
      const anuncios = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      callback(anuncios);
    });
  };
}

export default new anunciosService();