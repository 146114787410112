import React, { useState } from "react";
import { DefaultLayout } from "../components/defaultLayout";
import { Container } from "../styles/anuncios";
import { Button, Form, Input,  message, InputNumber, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { storage } from "../lib/client";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import obrasService from "../services/obras.service";
import { v4 as uuidv4 } from "uuid";
import { UploadCustom } from "../components/uploadCustom";



function Anuncios() {
    const [fileList, setFileList] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';

    const [form] = Form.useForm();

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    const criarNovoItem = async (data) => {
        obrasService.addObras(data);
        form.resetFields();
        messageApi.open({
            key,
            type: 'success',
            content: 'Obra registrada com sucesso!',
            duration: 2,
        });
    };

    const handleFinish = async (values) => {
        setHasError(false);
        messageApi.open({
            key,
            type: 'loading',
            content: 'Carregando...',
        });
        try {
            setSubmitting(true);

            const uploadPromises = fileList.map(async (file) => {
                const uniqueFileName = uuidv4() + "-" + file.name;
                const fileRef = ref(storage, `images/${uniqueFileName}`);
                const uploadTask = uploadBytesResumable(fileRef, file);

                return new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        null,
                        (error) => reject(error),
                        async () => {
                            // Upload completed successfully
                            try {
                                const url = await getDownloadURL(uploadTask.snapshot.ref);
                                resolve(url);
                            } catch (error) {
                                reject(error);
                            }
                        }
                    );
                });
            });

            const resolvedUrls = await Promise.all(uploadPromises);

            const newData = {
                titulo: values.titulo,
                descricao: values.descricao,
                categoria: values.categorias,
                localizacao: values.localizacao,
                imagesUrl: resolvedUrls
            };

            await criarNovoItem(newData);

            setFileList([]);

        } catch (err) {
            console.log(err); 
            setHasError(true);
            messageApi.open({
                key,
                type: 'error',
                content: 'Erro em criar anúncio',
            });
        } finally {
            setSubmitting(false)
        }
    };

    return (
        <DefaultLayout active="obras" title="Obras">
            <Container>
            {contextHolder}
                <Form
                    name="obras"
                    form={form}
                    title="Cadastro de Obras"
                    layout="vertical"
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{ width: "100%", maxWidth: "600px" }}
                >
                    <Form.Item
                        label="Titulo"
                        name="titulo"
                        rules={[
                            {
                                required: true,
                                message: 'Porfavor, preencha o campo!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="descricao"
                        label="Descrição"
                        rules={[{ required: true, message: 'Porfavor preencha o campo!' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        name="categorias"
                        label="Categorias"
                        rules={[{ required: true, message: 'Porfavor preencha o campo!' }]}
                    >
                        <Select>
                            <Select.Option value="Obra em Andamento">Obra em Andamento</Select.Option>
                            <Select.Option value="Obra Concluída">Obra Concluída</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Localização"
                        name="localizacao"
                        rules={[
                            {
                                required: true,
                                message: 'Porfavor, preencha o campo!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Imagens" valuePropName="fileList" getValueFromEvent={normFile}>
                        <UploadCustom
                            setFileList={setFileList}
                            fileList={fileList}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 0,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            {submitting ? <LoadingOutlined /> : "Criar anúncio"}
                        </Button>
                    </Form.Item>
                </Form>
            </Container>
        </DefaultLayout>
    )
}


export default Anuncios;