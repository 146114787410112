import { collection, doc, getDocs, addDoc, deleteDoc, updateDoc, getDoc, onSnapshot } from "firebase/firestore";
import { storage, database } from "../lib/client";
import { ref, deleteObject } from 'firebase/storage';

const obrasCollectionRef = collection(database, "obras");

const extractFileNameFromUrl = (url) => {
  const regex = /%2F(.+?)\?/;
  const match = url.match(regex);
  return match && match[1] ? match[1] : null;
};

class ObrasService {
  async addObras(obrasData) {
    const docRef = await addDoc(obrasCollectionRef, obrasData);
    return docRef.id;
  }

  async getObras() {
    const obras = await getDocs(obrasCollectionRef);
    return obras.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  }

  async getObra(docId) {
    const docRef = doc(obrasCollectionRef, docId);
    const obraSnapshot = await getDoc(docRef);
    return obraSnapshot.data();
  }

  async deleteObras(docData) {
    try {
      const docRef = doc(obrasCollectionRef, docData.id);

      await Promise.all(
        docData.imagesUrl.map(async (imageUrl) => {
          const fileName = extractFileNameFromUrl(imageUrl);
          const fileRef = ref(storage, `images/${fileName}`);
          await deleteObject(fileRef);
        })
      );

      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  async updateObras(docData) {
    try {
      const docRef = doc(obrasCollectionRef, docData.id);
      await updateDoc(docRef, docData);
      return true;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  subscribeObras(callback) {
    return onSnapshot(obrasCollectionRef, (querySnapshot) => {
      const obras = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      callback(obras);
    });
  }
}

export default new ObrasService();