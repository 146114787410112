import React, { useState, useEffect, useRef } from "react";
import { Header, Container, HeaderMobile } from "../styles/dashboard/defaultLayout";
import { Cadastro } from "../styles/dashboard/main";
import { Link } from "react-router-dom";

import { HomeOutlined, PoweroffOutlined, FileAddOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { AnimatePresence, motion } from "framer-motion";


import logo from '../assets/voragoassets/LOGOS/LOGO PNG 2.png';
import usePersistedState from './usePersistedState';


function useClickOutside(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, callback]);
}

export const DefaultLayout = ({ children, active, title }) => {

    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);

    const headerRef = useRef(null);

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("userToken");
        navigate("/admin");
    }

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useClickOutside(headerRef, () => {
        if (open) {
            setOpen(false);
        }
    });

    return (
        <Container style={{ flexDirection: width <= 1280 ? "column" : "row" }}>
            {
                width <= 1280 ?
                    (
                        <HeaderMobile ref={headerRef}>
                            <MenuOutlined style={{ fontSize: "1.5rem", color: "#313347" }} className="menu" onClick={() => setOpen(!open)} />
                            <AnimatePresence>
                                {open && (
                                    <motion.div
                                        className="header-card"
                                        layout
                                        initial={{ left: "-200px", opacity: 0 }}
                                        animate={{ left: 0, opacity: 1 }}
                                        exit={{ left: "-200px", opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                        onClick={() => setOpen(false)}
                                        
                                    >
                                        <div className="header">
                                            <Link to="/"><img className="logo" src={logo} /></Link>
                                        </div>
                                        <div className="content">
                                            <Link className={active === "dashboard" ? "active" : ""} to="/admin/dashboard">
                                                <HomeOutlined /> Home
                                            </Link>
                                            <Link className={active === "anuncios" ? "active" : ""} to="/admin/dashboard/anuncios">
                                                <FileAddOutlined /> Anuncio
                                            </Link>
                                            <Link className={active === "obras" ? "active" : ""} to="/admin/dashboard/obras">
                                                <FileAddOutlined /> Obras
                                            </Link>
                                        </div>
                                        <div className="footer">
                                            <span className="span" onClick={() => handleLogout()}><PoweroffOutlined />Sair</span>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </HeaderMobile>
                    )
                    :
                    (
                        <Header>
                            <div className="header">
                            <Link to="/"><img className="logo" src={logo} /></Link>
                            </div>
                            <div className="content">
                                <Link className={active === "dashboard" ? "active" : ""} to="/admin/dashboard">
                                    <HomeOutlined /> Home
                                </Link>
                                <Link className={active === "anuncios" ? "active" : ""} to="/admin/dashboard/anuncios">
                                    <FileAddOutlined /> Anuncio
                                </Link>
                                <Link className={active === "obras" ? "active" : ""} to="/admin/dashboard/obras">
                                    <FileAddOutlined /> Obras
                                </Link>
                            </div>
                            <div className="footer">
                                <span className="span" onClick={() => handleLogout()}><PoweroffOutlined />Sair</span>
                            </div>
                        </Header>
                    )
            }
            <Cadastro style={{ marginLeft: width <= 1280 ? "0px" : "250px" }}>
                <div className="content">
                    <div className="title">
                        {title}
                    </div>
                    <div className="content-children">
                        {children}
                    </div>
                </div>
            </Cadastro>
        </Container>
    )
}
