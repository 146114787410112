import { styled } from "styled-components";


export const Testemunhos = styled.section`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;

    .content{
        width: 90%;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 100px 0px;

        gap: 70px;
    }

    .title-testemunhos-content{
        width: 90%;
        max-width: 555px;

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 27px;

    }

    .title-testemunhos{
        font-size: 2.25rem;
        color: #222222;
    }

    .subtitle-testemunhos{
        color: #999999;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
        line-height: 24px;
    }

    .testemunhos-content{
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;

        flex-direction: column;


        .top-testemunhos{
            width: 100%;

            display: flex;

            gap: 50px;
            justify-content: center;
        }

        .bottom-testemunhos{
            width: 100%;

            display: flex;

            gap: 50px;
            justify-content: center;
        }
    }

    .column{
        display: flex;
        width: 40%;
        gap: 30px;
    }

    .row{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .testemunho-p{
        font-size: 1rem;
        line-height: 35px;
        color: #999999;
    }

    .testemunho-person{
        font-weight: 700;
        font-size: 0.8rem;
    }

    .img-testemunho{
        width: 70px;
        height: 70px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .img-testemunho img{
        width: 70px;
    }

    @media screen and (max-width: 1026px){
        .subtitle-testemunhos{
            width: 90%;
            max-width: 400px;
        }
    }

    @media screen and (max-width: 1000px){
        .top-testemunhos{
            flex-direction: column;
            align-items: center;
        }

        .bottom-testemunhos{
            flex-direction: column;
            align-items: center;
        }

        .column{
            width: 100%;
        }
    }

    @media screen and (max-width: 500px){
        .title-testemunhos{
            font-size: 1.4rem;
        }

        .column{
            flex-direction: column;
            align-items: center;
        }

        .img-testemunho{
            text-align: center;
        }
    }
    
`