import React, { useContext, createContext, useEffect, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./client";
import { useNavigate } from "react-router";


const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

    const navigate = useNavigate();

    const [user, setUser] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setUser(localStorage.getItem("userToken"))
    }, [])

    const sign = async (props) => {
      
        await signInWithEmailAndPassword(auth, props.email, props.senha)
          .then(async (userCredential) => {
            // Signed in 
            setError(false);
            setIsLoading(false);
            
            
            const userToken = await userCredential.user.getIdToken();
            localStorage.setItem("userToken", userToken);
            setUser(userToken);
            navigate("/admin/dashboard");


          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(true);
            setIsLoading(false);
          });
      };


    return (
        <AuthContext.Provider value={{ user, setUser, sign, error, setError, isLoading, setIsLoading }}>
            {children}
        </AuthContext.Provider>
    );
}


export const useAuth = () => {
    return useContext(AuthContext);
};