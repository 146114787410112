import { Modal, Upload } from 'antd';
import React, { useState, useEffect } from 'react';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { InboxOutlined } from '@ant-design/icons';

export const UploadCustom = ({ setFileList, fileList }) => {

    const DraggableUploadListItem = ({ originNode, file }) => {
        const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
            id: file.uid,
        });
        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            cursor: 'move',
        };
        return (
            <div
                ref={setNodeRef}
                style={style}
                // prevent preview event when drag end
                className={isDragging ? 'is-dragging' : ''}
                {...attributes}
                {...listeners}
            >
                {/* hide error tooltip when dragging */}
                {file.status === 'error' && isDragging ? originNode.props.children : originNode}
            </div>
        );
    };

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setFileList((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id);
                const overIndex = prev.findIndex((i) => i.uid === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    });

    const props = {
        name: 'Images',
        multiple: true,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        itemRender: (originNode, file) => (
            <DraggableUploadListItem originNode={originNode} file={file} />
        ),
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                setFileList(info.fileList.map(file => file.originFileObj));
            }
        },
        fileList,
    };

    return (
        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
            <SortableContext items={fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                <Upload.Dragger
                    {...props}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text" style={{ margin: "20px 20px" }}>Clique ou arraste o arquivo para esta área para carregar</p>
                </Upload.Dragger>
            </SortableContext>
        </DndContext>
    );
};