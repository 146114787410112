import React, { useEffect, useState } from "react";
import { DefaultLayout } from "../components/defaultLayout";
import { Container } from "../styles/anuncios";
import { Button, Form, Input, Upload, message, InputNumber, Select } from 'antd';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAuth } from "../lib/authContext";
import { storage } from "../lib/client";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import anuncioService from "../services/anuncio.service";
import { v4 as uuidv4 } from "uuid";
import { UploadCustom } from "../components/uploadCustom";



function Anuncios() {
    const [fileList, setFileList] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [imagesUrl, setImagesUrl] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';

    const [form] = Form.useForm();

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };


    const criarNovoItem = async (data) => {
        anuncioService.addAnuncio(data);
        form.resetFields();
        messageApi.open({
            key,
            type: 'success',
            content: 'Anúncio criado com sucesso!',
            duration: 2,
        });
    };

    const handleFinish = async (values) => {
        setHasError(false);
        messageApi.open({
            key,
            type: 'loading',
            content: 'Carregando...',
        });
        try {
            setSubmitting(true);

            const uploadPromises = fileList.map(async (file) => {
                console.log(`O nome do file: ${file}`)
                const uniqueFileName = uuidv4() + "-" + file.name;
                const fileRef = ref(storage, `images/${uniqueFileName}`);
                const uploadTask = uploadBytesResumable(fileRef, file);

                return new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        null,
                        (error) => reject(error),
                        async () => {
                            // Upload completed successfully
                            try {
                                const url = await getDownloadURL(uploadTask.snapshot.ref);
                                console.log(`Caindo no URL ${url}`);
                                resolve(url);
                            } catch (error) {
                                reject(error);
                            }
                        }
                    );
                });
            });

            const resolvedUrls = await Promise.all(uploadPromises);

            const newData = {
                titulo: values.titulo,
                preco: values.preço,
                descricao: values.descricao,
                categoria: values.categorias,
                localizacao: values.localizacao,
                imagesUrl: resolvedUrls
            };

            await criarNovoItem(newData);

            setFileList([]);

        } catch (err) {
            setHasError(true);
            messageApi.open({
                key,
                type: 'error',
                content: 'Erro em criar anúncio',
            });
        } finally {
            setSubmitting(false)
        }
    };

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    return (
        <DefaultLayout active="anuncios" title="Anuncios">
            <Container>
            {contextHolder}
                <Form
                    name="anuncios"
                    form={form}
                    title="Cadastro de Anúncio"
                    layout="vertical"
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{ width: "100%", maxWidth: "600px" }}
                >
                    <Form.Item
                        label="Titulo"
                        name="titulo"
                        rules={[
                            {
                                required: true,
                                message: 'Porfavor, preencha o campo!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="preço"
                        label="Preço"
                        rules={[{ required: true, message: 'Porfavor preencha o campo!' }]}
                    >
                        <InputNumber
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="descricao"
                        label="Descrição"
                        rules={[{ required: true, message: 'Porfavor preencha o campo!' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        name="categorias"
                        label="Categorias"
                        rules={[{ required: true, message: 'Porfavor preencha o campo!' }]}
                    >
                        <Select>
                            <Select.Option value="Apartamento">Apartamento</Select.Option>
                            <Select.Option value="Casa">Casa</Select.Option>
                            <Select.Option value="Terreno">Terreno</Select.Option>
                            <Select.Option value="Outros">Outros</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Localização"
                        name="localizacao"
                        rules={[
                            {
                                required: true,
                                message: 'Porfavor, preencha o campo!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Imagens" valuePropName="fileList" getValueFromEvent={normFile}>
                        <UploadCustom
                            setFileList={setFileList}
                            fileList={fileList}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 0,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            {submitting ? <LoadingOutlined /> : "Criar anúncio"}
                        </Button>
                    </Form.Item>
                </Form>
            </Container>
        </DefaultLayout>
    )
}


export default Anuncios;