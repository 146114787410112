import React, { useEffect } from "react";

import { DefaultLayout } from "../components/defaultLayout";
import { Container } from "../styles/dashboard/dashboard";

import { AnunciosContent } from "../components/anunciosContent";
import { ObrasContent } from "../components/obrasContent";

import { Button } from "antd";
import usePersistedState from '../components/usePersistedState';


function Dashboard() {

    const [filtro, setFiltro] = usePersistedState("filtro", "anuncios");

    const handleFiltro = (filtro) => {
        setFiltro(filtro);
    }

    useEffect(() => {
        document.title = "Vorago - Dashboard";
    }, [])

    return (
        <DefaultLayout active="dashboard" title="Overview">
            <Container>
                <div className="filtros">
                    <div className="title-filtros">
                        Filtros
                    </div>
                    <div className="buttons">
                        <Button type={filtro === "anuncios" ? "primary" : "default" } onClick={() => handleFiltro("anuncios")}>Anúncios</Button>
                        <Button type={filtro === "obras" ? "primary" : "default" } onClick={() => handleFiltro("obras")}>Obras</Button>
                    </div>
                </div>
               {
                filtro === "anuncios" ?
                (
                    <AnunciosContent/>
                )

                :
                (
                    <ObrasContent/>
                )
               }
            </Container>
        </DefaultLayout >
    )
}

export default Dashboard;