import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Skeleton } from "antd";


import { Header, HeaderMobile } from "../styles/defaultLayout";
import { Footer } from "../styles/footer";
import { Content } from "../styles/slugObras/Content";

import { MenuOutlined, InstagramOutlined, FacebookOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Button, Image } from "antd";


import logo from '../assets/voragoassets/LOGOS/LOGO PNG 2.png';
import obrasService from "../services/obras.service";


function SlugObras() {
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const url = window.location.pathname;
            const parts = url.split('/'); // Divide a URL em partes usando '/'
            const docId = parts.pop(); // Obtém o último elemento do array
            try {
                const dataFetch = await obrasService.getObra(docId);
                document.title = `Vorago - ${dataFetch.titulo}`;
                console.log(dataFetch);
                setData(dataFetch);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                navigate("/404");
                console.error("Error getting data:", error);
            }
        }

        fetchData();

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!data) {
        return null;
    }

    return (
        <>
            {
                width <= 950 ? (
                    <HeaderMobile style={{ background: "#fff" }}>
                        <div className="content">
                            <div className="logo">
                                <Link to="/"><img src={logo} /></Link>
                            </div >
                            <MenuOutlined className="menu" onClick={() => setOpen(!open)} />
                        </div>
                        <AnimatePresence>
                            {open && (
                                <motion.div
                                    className="drawner"
                                    layout
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "200px", opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    onClick={() => setOpen(false)}
                                >
                                    <div className="container">
                                        <Link to="/">Sobre Nós</Link>
                                        <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                        <Link to="/obras">Obras</Link>
                                        <Link to="/contato">Contato</Link>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </HeaderMobile>
                )
                    : (
                        <Header>
                            <div className="content">
                                <div className="logo">
                                    <Link to="/"><img src={logo} /></Link>
                                </div >
                                <div className="navigation">
                                    <Link to="/">Sobre Nós</Link>
                                    <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                    <Link to="/obras">Obras</Link>
                                    <Link to="/contato">Contato</Link>
                                </div>
                            </div >
                        </Header >
                    )
            }
            <>
                <Content>
                    <div className="content">
                        {loading ? (
                            <Skeleton active />
                        ) : (
                            <>
                                <div className="title">
                                    {data?.titulo}
                                </div>
                                <div className="descricao" style={{ whiteSpace: 'pre-line' }}>
                                    {data?.descricao}
                                </div>
                                <div className="button">
                                    <a href="https://api.whatsapp.com/send/?phone=45999425066&text&type=phone_number&app_absent=0" target="_blank" type="primary">
                                        <WhatsAppOutlined />
                                        FALE CONOSCO
                                    </a>
                                </div>
                                <div className="images">
                                    {
                                        data?.imagesUrl.map((item, key) => (
                                            <Image src={item} key={key} />
                                        ))
                                    }
                                </div>
                                <div className="button">
                                    <a href="https://api.whatsapp.com/send/?phone=45999425066&text&type=phone_number&app_absent=0" target="_blank" type="primary">
                                        <WhatsAppOutlined />
                                        FALE CONOSCO
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </Content>
                <Footer>
                    <div className="content">
                        <p className="copyright">
                            © Vorago 2023, All Rights Reserved. Powered By <a href="nirayuki.netlify.app/" target="_blank">Nirayuki</a>
                        </p>
                        <div className="social">
                            <FacebookOutlined />
                            <a className="insta-a" href="https://instagram.com/construtoravorago" target="_blank"><InstagramOutlined  /></a>
                        </div>
                    </div>
                </Footer>
            </>
        </>
    )
}


export default SlugObras;