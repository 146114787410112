import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";


import { Header, HeaderMobile } from "../styles/defaultLayout";
import { Footer } from "../styles/footer";
import { Content } from "../styles/slugImoveis/content";

import { MenuOutlined, InstagramOutlined, FacebookOutlined, RightOutlined, LeftOutlined, EnvironmentOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { Image, Carousel, Button } from "antd";


import logo from '../assets/voragoassets/LOGOS/LOGO PNG 2.png';
import anuncioService from "../services/anuncio.service";
import { ModelFormContact } from "../components/modelFormContact";

const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', fontSize: '2rem',
        lineHeight: '1', color: "#D4A73A" , right: "15px", top: "50%",backgroundColor: "#fff", width: "35px", height: "35px", borderRadius: "3px", zIndex: "999" }}
        onClick={onClick}
      >
        <RightOutlined className="icon-arrow-right"/>
    </div>
    )
  }

  const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', fontSize: '2rem',
        lineHeight: '1', color: "#D4A73A ", left: "15px",  top: "50%",zIndex: "999", backgroundColor: "#fff", width: "35px", height: "35px", borderRadius: "3px", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"  }}
        onClick={onClick}
      >
        
        <LeftOutlined className="icon-arrow-left"/>
    </div>
    )
  }

  
  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  }
  

function SlugImoveis() {
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            const url = window.location.pathname;
            const parts = url.split('/'); // Divide a URL em partes usando '/'
            const docId = parts.pop(); // Obtém o último elemento do array
            try {
                const dataFetch = await anuncioService.getAnuncio(docId);
                document.title = `Vorago - ${dataFetch.titulo}`;
                setData(dataFetch);
            } catch (error) {
                navigate("/404");
                console.error("Error getting data:", error);
            }
        }

        fetchData();

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    if(!data){
        return null;
    }
    
    return (
        <>
            {
                width <= 950 ? (
                    <HeaderMobile style={{ background: "#fff" }}>
                        <div className="content">
                            <div className="logo">
                                <Link to="/"><img src={logo} /></Link>
                            </div >
                            <MenuOutlined className="menu" onClick={() => setOpen(!open)} />
                        </div>
                        <AnimatePresence>
                            {open && (
                                <motion.div
                                    className="drawner"
                                    layout
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "200px", opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    onClick={() => setOpen(false)}
                                >
                                    <div className="container">
                                        <Link to="/">Sobre Nós</Link>
                                        <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                        <Link to="/obras">Obras</Link>
                                        <Link to="/contato">Contato</Link>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </HeaderMobile>
                )
                    : (
                        <Header>
                            <div className="content">
                                <div className="logo">
                                    <Link to="/"><img src={logo} /></Link>
                                </div >
                                <div className="navigation">
                                    <Link to="/">Sobre Nós</Link>
                                    <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                    <Link to="/obras">Obras</Link>
                                    <Link to="/contato">Contato</Link>
                                </div>
                            </div >
                        </Header >
                    )
            }
            <>
                <Content>
                    <div className="content">
                        <div className="header">
                            <div className="images">
                                <Carousel
                                arrows
                                style={{
                                    margin: 0,
                                    height: '300px',
                                    width: "100%",
                                    color: 'black',
                                    textAlign: 'center',
                                    background: '#e9eaeb',
                                    borderRadius: "3px",
                                }}
                                dots={{
                                    className: "dots"
                                }}
                                {...settings}
                                >
                                    {
                                        data?.imagesUrl.map((item, key) => (
                                            <Image className="image-content" src={item} key={key}/>
                                        ))
                                    }
                                </Carousel>
                            </div>
                            <div className="card">
                                <div className="title-card">
                                    <p>{Number(data?.preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                    <div className="divider"/>
                                </div>
                                <div className="other-content">
                                    <div className="titulo">
                                        {data?.titulo}
                                    </div>
                                    <div className="localizacao">
                                        <EnvironmentOutlined />
                                        <p>{data?.localizacao}</p>
                                    </div>
                                    <div className="contatos">
                                        <a href="https://api.whatsapp.com/send/?phone=45999425066&text&type=phone_number&app_absent=0" target="_blank" type="primary">
                                            <WhatsAppOutlined />
                                            <p>Whatsapp</p>
                                        </a>
                                       <Link to="/contato">Enviar Mensagem</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="description" style={{ whiteSpace: 'pre-line' }}>
                            {data?.descricao}
                        </div>
                    </div>
                </Content>
                <Footer>
                    <div className="content">
                        <p className="copyright">
                            © Vorago 2023, All Rights Reserved. Powered By <a href="nirayuki.netlify.app/" target="_blank">Nirayuki</a>
                        </p>
                        <div className="social">
                            <FacebookOutlined />
                            <a className="insta-a" href="https://instagram.com/construtoravorago" target="_blank"><InstagramOutlined  /></a>
                        </div>
                    </div>
                </Footer>
            </>
        </>
    )
}


export default SlugImoveis;