import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { FloatButton } from "antd";



import { MenuOutlined, InstagramOutlined, FacebookOutlined, LoadingOutlined, WhatsAppOutlined } from '@ant-design/icons'

import logo from '../assets/voragoassets/LOGOS/LOGO PNG 2.png';
import semdata from '../assets/voragoassets/LOGOS/LOGO PNG 3.png';

import { Header, HeaderMobile } from "../styles/defaultLayout";
import { Home } from "../styles/imoveisAVenda/home";
import { Content } from "../styles/imoveisAVenda/content";
import { Footer } from "../styles/footer";


import { CardImoveisAVenda } from "../components/cardImoveisAVenda";

import anuncioService from "../services/anuncio.service";
import Image from "../components/Image";

function ImovesAVenda() {
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        document.title = "Vorago";
        const fetchData = async () => {
            try {
                const dataFetch = await anuncioService.getAnuncios();
                setData(dataFetch)

            } catch (error) {
                console.error("Error getting room data:", error);
            }
        }

        fetchData();
        setTimeout(() => {
            setLoading(false);
        }, "1000")
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {
                width <= 950 ? (
                    <HeaderMobile>
                        <div className="content">
                            <div className="logo">
                                <Link to="/"><img src={logo} /></Link>
                            </div >
                            <MenuOutlined className="menu" onClick={() => setOpen(!open)} />
                        </div>
                        <AnimatePresence>
                            {open && (
                                <motion.div
                                    className="drawner"
                                    layout
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "200px", opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    onClick={() => setOpen(false)}
                                >
                                    <div className="container">
                                        <Link to="/">Sobre Nós</Link>
                                        <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                        <Link to="/obras">Obras</Link>
                                        <Link to="/contato">Contato</Link>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </HeaderMobile>
                )
                    : (
                        <Header>
                            <div className="content">
                                <div className="logo">
                                    <Link to="/"><img src={logo} /></Link>
                                </div >
                                <div className="navigation">
                                    <Link to="/">Sobre Nós</Link>
                                    <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                    <Link to="/obras">Obras</Link>
                                    <Link to="/contato">Contato</Link>
                                </div>
                            </div >
                        </Header >
                    )
            }
            <>
                <Home />
                <Content>
                    <div className="content">
                        <div className="title">
                            Imóveis a Venda
                        </div>
                        <div className="content-venda">
                            {loading ? (
                                <div className="loading">
                                    <LoadingOutlined/>
                                </div>
                            ) : (
                                <>
                                    {data?.length === 0 ? (
                                        <div className="nodata">
                                            <Image loading="lazy" src={semdata} />
                                            Sem dados
                                        </div>
                                    ) :
                                        (
                                            <>
                                                {data?.map((item, key) => (
                                                    <CardImoveisAVenda data={item} key={key} loading={loading} />
                                                ))}
                                            </>
                                        )
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </Content>
                <Footer>
                    <div className="content">
                        <p className="copyright">
                            © Vorago 2023, All Rights Reserved. Powered By <a href="https://nirayuki.netlify.app/" target="_blank">Nirayuki</a>
                        </p>
                        <div className="social">
                            <FacebookOutlined />
                            <a className="insta-a" href="https://instagram.com/construtoravorago" target="_blank"><InstagramOutlined  /></a>
                        </div>
                    </div>
                </Footer>
                <FloatButton type="primary" icon={<WhatsAppOutlined/>} href="https://api.whatsapp.com/send/?phone=45999425066&text&type=phone_number&app_absent=0" target="_blank"/>
            </>
        </>
    )
}


export default ImovesAVenda;