import React from "react";
import SkeletonDetails from '../styles/components/SkeletonDetails';

const Skeleton = ({width, height, style, className}) => {
    return(
        <SkeletonDetails className={className} style={{...style}} $width={width} $height={height}/>
    )
}


export default Skeleton;