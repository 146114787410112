import { styled } from "styled-components";

export const Trabalho = styled.section`

    width: 100%;
    

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgb(248, 248, 248);
    opacity: 1;

    .content{
        width: 90%;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 100px 0px;

        gap: 70px;
    }

    .title-trabalho-content{
        width: 90%;
        max-width: 555px;

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 27px;

    }

    .title-trabalho{
        font-size: 2.25rem;
        color: #222222;
    }

    .subtitle-trabalho{
        color: #999999;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: center;
        line-height: 24px;
    }

    .trabalho-content{
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 50px;

    }

    .breadcrumb{
        font-size: 1.2rem;
    }

    .breadcrumb button{
        background-color: transparent;
        border: none;
        cursor: pointer;
    }

    .breadcrumb button:hover{
        background-color: transparent;
        color: #D4A73A;
    }

    .active{
        color: #D4A73A;
    }

    .fotos{
        width: 87%;

        display: flex;
        justify-content: center;
        column-gap: 35px;

        flex-wrap: wrap;
        row-gap: 30px;
    }

    @media screen and (max-width: 1026px){
        .subtitle-trabalho{
            width: 90%;
            max-width: 400px;
        }
    }

    @media screen and (max-width: 550px){
        .title-trabalho{
            font-size: 1.4rem;
        }

        .breadcrumb{
            font-size: 0.85rem;
        }
    }

`