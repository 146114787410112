import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

import { FloatButton, message } from "antd";
import { MenuOutlined, InstagramOutlined, FacebookOutlined, WhatsAppOutlined, LoadingOutlined } from '@ant-design/icons';

import emailjs from '@emailjs/browser';

import logo from '../assets/voragoassets/LOGOS/LOGO PNG 2.png';

import { Header, HeaderMobile } from "../styles/defaultLayout";
import { Home } from "../styles/imoveisAVenda/home";
import { Content } from "../styles/Contato/content";
import { Footer } from "../styles/footer";

const initialValues = {
    user_name: "",
    user_email: "",
    message: ""
}

function Contato() {
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const key = 'updatable';

    const formRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        document.title = "Vorago";

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        emailjs.sendForm('service_8qa4dpm', 'template_uphcu5p', formRef.current, '7rhvry256sDvZZzRC')
            .then((result) => {
                messageApi.open({
                    key,
                    type: 'success',
                    content: 'Contato enviado!',
                    duration: 2,
                });
                setForm(initialValues);
                setLoading(false);
            }, (error) => {
                messageApi.open({
                    key,
                    type: 'error',
                    content: 'Algo deu errado :(',
                    duration: 2,
                });
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value });
    }

    return (
        <>
            {contextHolder}
            {
                width <= 950 ? (
                    <HeaderMobile>
                        <div className="content">
                            <div className="logo">
                                <Link to="/"><img src={logo} /></Link>
                            </div >
                            <MenuOutlined className="menu" onClick={() => setOpen(!open)} />
                        </div>
                        <AnimatePresence>
                            {open && (
                                <motion.div
                                    className="drawner"
                                    layout
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "200px", opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    onClick={() => setOpen(false)}
                                >
                                    <div className="container">
                                        <Link to="/">Sobre Nós</Link>
                                        <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                        <Link to="/obras">Obras</Link>
                                        <Link to="/contato">Contato</Link>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </HeaderMobile>
                )
                    : (
                        <Header>
                            <div className="content">
                                <div className="logo">
                                    <Link to="/"><img src={logo} /></Link>
                                </div >
                                <div className="navigation">
                                    <Link to="/">Sobre Nós</Link>
                                    <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                    <Link to="/obras">Obras</Link>
                                    <Link to="/contato">Contato</Link>
                                </div>
                            </div >
                        </Header >
                    )
            }
            <>
                <Home />
                <Content>
                    <div className="content">
                        <div className="title">
                            Fale Conosco
                        </div>
                        <div className="form-card">
                            <form ref={formRef} onSubmit={handleSubmit}>
                                <div className="row">
                                    <label for="name">Nome</label>
                                    <input className="input" name="user_name" value={form.user_name} placeholder="Digite seu nome aqui..." onChange={handleChange} />
                                </div>
                                <div className="row">
                                    <label for="email">Email</label>
                                    <input className="input" name="user_email" value={form.user_email} placeholder="Digite seu email aqui..." onChange={handleChange} />
                                </div>
                                <div className="row">
                                    <label for="message">Mensagem</label>
                                    <textarea name="message" value={form.message} placeholder="Digite sua mensagem aqui..." onChange={handleChange} />
                                </div>
                                <div className="row">
                                    {
                                        loading ? (
                                            <button className="submit" disabled="true"><LoadingOutlined /></button>
                                        ) : (
                                            <input type="submit" value="Enviar" className="submit" />
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </Content>
                <Footer>
                    <div className="content">
                        <p className="copyright">
                            © Vorago 2023, All Rights Reserved. Powered By <a href="https://nirayuki.netlify.app/" target="_blank">Nirayuki</a>
                        </p>
                        <div className="social">
                            <FacebookOutlined />
                            <a className="insta-a" href="https://instagram.com/construtoravorago" target="_blank"><InstagramOutlined  /></a>
                        </div>
                    </div>
                </Footer>
                <FloatButton type="primary" icon={<WhatsAppOutlined />} href="https://api.whatsapp.com/send/?phone=45999425066&text&type=phone_number&app_absent=0" target="_blank" />
            </>
        </>
    )
}


export default Contato;