import { styled } from "styled-components";

const ImageDetails = styled.div`
    width: ${props => props.$width ? props.$width : "auto"};
    height: ${props => props.$height ? props.$height : "100%"};

    position: relative;

    border-radius: 6px;

    img{
        width: ${props => props.$width ? props.$width : "auto"};
        height: ${props => props.$height ? props.$height : "100%"};

        object-fit: cover;
    }
`

export default ImageDetails;