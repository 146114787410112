import { styled } from "styled-components";

export const Skills = styled.section`
    width: 100%;
    

    display: flex;
    align-items: center;
    justify-content: center;

    .content{
        width: 75%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        margin: 100px 0px;
    }

    .left{
        width: 50%;
        display: flex;
        flex-direction: column;

        gap: 20px;
    }

    .title-skills{
        font-size: 2.25rem;
        line-height: 50px;
        font-weight: 400;
        color: #D4A73A;
    }

    .subtitle-skills{
        font-size: 1rem;
        font-weight: 400;
        line-height: 26px;
        color: #999999;
        text-align: justify;
    }

    .right{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img{
            width: 100%;
        }
    }

    .content-progress{
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .header-progress{
        display: flex;
        justify-content: space-between;
    }

    .header-progress p{
        font-size: 0.75rem;
        color: #333;
        font-weight: 700;
        letter-spacing: 1px;
    }

    @media screen and (max-width: 1260px){
        .content{
            flex-direction: column;
            gap: 100px;
        }

        .left{
            width: 95%;
            max-width: 750px;
        }

        .right{
            width: 95%;
            max-width: 750px;
            justify-content: center;

            img{
                width: 400px;
            }
        }

    }

    @media screen and (max-width: 470px){
        .title-skills{
            font-size: 1.5rem;
            line-height: 30px;
        }

        .left{
            width: 100%;
            max-width: 750px;
        }

        .right{
            width: 100%;
            max-width: 750px;

            img{
                width: 300px;
            }
        }
    }
`