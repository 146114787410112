import { styled } from "styled-components";


export const Content = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;

    .content{
        max-width: 1300px;
        width: 100%;
        padding: 20px;

        margin: 50px 0px;
        margin-bottom: 100px;
        gap: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }


    .title{
        font-size: 2.5rem;
        color: #222222;
    }

    .content-obra{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .filtros{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .filtros button{
        background: none;
        border: none;
        font-size: 1.2rem;
        cursor: pointer;
    }

    .filtros button:hover{
        color: #D4A73A;
    }

    .active{
        color: #D4A73A;
    }


    .content-data{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 50px;
    }

    .nodata{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nodata img{
        width: 150px;
        opacity: 0.5;
    }

    .dots button{
        background-color: black !important;
        height: 14px !important;
        width: 14px !important;
        border-radius: 50% !important;
    }

    .carousel{
    }

    .obras-andamento{
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .obras-concluida{
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .title-obras{
        font-size: 1.5rem;
    }

   .card{
        width: 100%;
        height: 270px;
        padding: 15px;
        margin: 20px 0px;

        border-radius: 5px;

        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

        cursor: pointer;

        transition: all .2s ease-in-out;
        text-align: center;
   }

   .card:hover{
        transform: translateY(-6px);
   }
   
    .imagem img{
        width: 100%;
        height: 150px;
        object-fit: contain;
    }

    .categoria-card{
        font-size: 1.2rem;
        font-weight: 600;
    }

    .preco-card{
        font-size: 1.5rem;
        font-weight: 600;
    }


    .title-card{
        color: #333333;
        padding: 10px;
        padding-top: 30px;
    }

    .local{
        font-weight: bold;
        padding: 10px;
        margin-bottom: 10px;
    }
`
