import { styled } from "styled-components";


export const SectionInform = styled.section`
    width: 100%;

    padding: 75px 0px;
    background-color: #D4A73A;

    text-align: center;

    h3{
        color: #fff;
        font-size: 1.875rem;
        font-weight: 400;
        line-height: 40px;
    }

    @media screen and (max-width: 600px){
        h3{
            font-size: 1.3rem;
            line-height: 30px;
        }
    }
`   