import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Header, HeaderMobile } from "../styles/defaultLayout";
import { Home } from "../styles/home";
import { Servicos } from "../styles/servicos";
import { Trabalho } from "../styles/trabalho";
import { Skills } from "../styles/skills";
import { SectionInform } from "../styles/sectionInform";
import { Testemunhos } from "../styles/testemunhos";
import { Mapa } from "../styles/mapa";
import { Endereco } from "../styles/endereco";
import { Footer } from "../styles/footer";

import { Button, Breadcrumb, Image, FloatButton, Progress } from "antd";
import { MobileOutlined, MailOutlined, HomeOutlined, InstagramOutlined, FacebookOutlined, MenuOutlined, WhatsAppOutlined } from '@ant-design/icons'
import { motion, AnimatePresence } from "framer-motion";

import logo from '../assets/voragoassets/LOGOS/LOGO PNG 2.png';
import skyline from '../assets/icons/skyline.png';
import capacete from '../assets/icons/capacete.png';
import truck from '../assets/icons/crane-truck.png';
import excavator from '../assets/icons/excavator.png'
import person1 from '../assets/testemorials/1.jpg';
import person2 from '../assets/testemorials/2.jpg';

import icon_logo from '../assets/voragoassets/LOGOS/LOGO PNG 3.png';

import { dataCategorias } from "../components/dataCategorias";


const getFilter = (nameFilter, dataCategorias, setDataFilter, setIsAnimating) => {
    if (nameFilter === "all") {
        setDataFilter(dataCategorias);
        setIsAnimating(nameFilter);
    } else {
        const filteredData = dataCategorias.filter(filter => filter.filter === nameFilter);
        setIsAnimating(nameFilter);
        setDataFilter(filteredData);
    }
}

function Main() {

    const [isAnimating, setIsAnimating] = useState("all");
    const [dataFilter, setDataFilter] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        document.title = "Vorago";
        setDataFilter(dataCategorias);
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            {width <= 950 ? (
                <HeaderMobile style={{ backgroundColor: "#fff" }}>
                    <div className="content">
                        <div className="logo">
                            <Link to="/"><img src={logo} /></Link>
                        </div >
                        <MenuOutlined className="menu" onClick={() => setOpen(!open)} />
                    </div>
                    <AnimatePresence>
                        {open && (
                            <motion.div
                                className="drawner"
                                layout
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height: "200px", opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                onClick={() => setOpen(false)}
                            >
                                <div className="container">
                                    <Link to="/">Sobre Nós</Link>
                                    <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                    <Link to="/obras">Obras</Link>
                                    <Link to="/contato">Contato</Link>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </HeaderMobile>
            )
                : (
                    <Header>
                        <div className="content">
                            <div className="logo">
                                <Link to="/"><img src={logo} /></Link>
                            </div >
                            <div className="navigation">
                                <Link to="/">Sobre Nós</Link>
                                <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                <Link to="/obras">Obras</Link>
                                <Link to="/contato">Contato</Link>
                            </div>
                        </div >
                    </Header >
                )
            }
            <>
                <Home id="home">
                    <div className="content">
                        <h1 className="title-home">Construimos o seu futuro</h1>
                        <p className="subtitle-home">Seu sonho mais próximo de você!</p>
                        <a className="button-home" href="https://api.whatsapp.com/send/?phone=45999425066&text&type=phone_number&app_absent=0" target="_blank">Faça uma cotação</a>
                    </div>
                </Home>
                <Servicos id="servicos">
                    <div className="content">
                        <div className="title-servicos-content">
                            <p className="title-servicos">
                                Nossos Serviços
                            </p>
                        </div>
                        <div className="icons-content">
                            <div className="icon-content">
                                <img className="img-icon" src={excavator} />
                                <div className="content-icon">
                                    <p className="title-icon">
                                        Administração de obras
                                    </p>
                                    <p className="subtitle-icon">
                                        Temos nossos serviços pautados na excelência, rapidez e comprometimento. Com
                                        processos eficientes e uma equipe altamente qualificada, entregamos obras de excelente
                                        qualidade, com processos otimizados, minimizando possibilidades de atrasos.
                                    </p>
                                </div>
                            </div>
                            <div className="icon-content">
                                <img className="img-icon" src={truck} />
                                <div className="content-icon">
                                    <p className="title-icon">
                                        Elaboração de projetos
                                    </p>
                                    <p className="subtitle-icon">
                                        Combinando design moderno, materiais de alta qualidade e atenção aos detalhes, aqui na
                                        Vorago criamos projetos funcionais e personalizados, garantindo resultados excepcionais,
                                        tanto em projetos residenciais, como comerciais.
                                    </p>
                                </div>
                            </div>
                            <div className="icon-content">
                                <img className="img-icon" src={capacete} />
                                <div className="content-icon">
                                    <p className="title-icon">
                                        Regularização de obras
                                    </p>
                                    <p className="subtitle-icon">
                                        Guiamos os clientes por todo o processo, agilizando licenças, documentação e aprovações
                                        necessárias para a regularização tranquila e eficiente de seu imóvel.
                                    </p>
                                </div>
                            </div>
                            <div className="icon-content">
                                <img className="img-icon" src={skyline} />
                                <div className="content-icon">
                                    <p className="title-icon">
                                        Financiamento de imóveis
                                    </p>
                                    <p className="subtitle-icon">
                                        A Construtora Vorago torna o financiamento de imóveis acessível e descomplicado. Com
                                        parcerias sólidas no setor financeiro, oferecemos opções personalizadas de financiamento
                                        que se adequam às necessidades individuais de seus clientes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Servicos>
                <Trabalho id="trabalho">
                    <div className="content">
                        <div className="title-trabalho-content">
                            <div className="title-trabalho">
                                Trabalho em Destaque
                            </div>
                            <div className="subtitle-trabalho">
                                Aqui você verá todos os trabalhos feitos pela Vorago Construtora.
                            </div>
                        </div>
                        <div className="trabalho-content">
                            <div className="fotos">
                                    {dataCategorias.map((item) => (
                                        <Image
                                            src={item.src}
                                            width={"300px"}
                                            height={"200px"}
                                            style={{objectFit: "cover"}}
                                        />
                                    ))}

                            </div>
                        </div>
                    </div>
                </Trabalho>
                <Skills id="habilidades">
                    <div className="content">
                        <div className="left">
                            <div className="title-skills">
                                Somos especialistas no ramo de construção civil.
                            </div>
                            <div className="subtitle-skills">
                                Bem-vindo à Vorago Construtora: Onde a excelência ganha vida, construímos espaços
                                que contam histórias, realizam sonhos e definem vidas. Nossa jornada é guiada por pilares
                                de comprometimento com a qualidade, atendimento personalizado, eficiência e dedicação
                                com cada projeto e obra que realizamos.
                            </div>
                        </div>
                        <div className="right">
                            <img src={icon_logo}/>
                        </div>
                    </div>
                </Skills>
                <SectionInform>
                    <h3>Uma empresa inovadora que trabalha com as mais recentes tecnologias</h3>
                </SectionInform>
                <Testemunhos id="testemunhos">
                    <div className="content">
                        <div className="title-testemunhos-content">
                            <p className="title-testemunhos">
                                Nossos Clientes Dizem
                            </p>
                        </div>
                        <div className="testemunhos-content">
                            <div className="top-testemunhos">
                                <div className="column">
                                    <div className="img-testemunho">
                                        <img src={icon_logo} alt="Person 1" />
                                    </div>
                                    <div className="row">
                                        <p className="testemunho-p">“Estou impressionada em como ficou minha casa, desde o momento em que entrei, pude
                                            sentir a atenção dedicada em cada detalhe, muito obrigada mais uma vez ao pessoal da
                                            construtora.
                                            ”</p>
                                        <p className="testemunho-person">Wagneia</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="img-testemunho">
                                        <img src={icon_logo} alt="Person 2" />
                                    </div>
                                    <div className="row">
                                        <p className="testemunho-p">“O atendimento recebi durante todo o processo, desde a escolha do projeto até a entrega
                                            das chaves, foi excepcional. Equipe muito atenciosa. Além da obra ter sido tranquila e
                                            terem entregado no prazo, obrigado!
                                            ”</p>
                                        <p className="testemunho-person">Rebeca</p>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-testemunhos">
                                <div className="column">
                                    <div className="img-testemunho">
                                        <img src={icon_logo} alt="Person 1" />
                                    </div>
                                    <div className="row">
                                        <p className="testemunho-p">“Não poderia estar mais feliz com o resultado final. A obra superou todas as minhas
                                            expectativas, gratidão!
                                            ”</p>
                                        <p className="testemunho-person">Luis Carlos</p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="img-testemunho">
                                        <img src={icon_logo} alt="Person 2" />
                                    </div>
                                    <div className="row">
                                        <p className="testemunho-p">“O que mais me impressionou foi a atenção que recebi, a equipe ouviu minhas ideias,
                                            entenderam minhas necessidades e transformaram tudo em realidade, agora sim tenho
                                            minha casa do jeito que sonhava.
                                            ”</p>
                                        <p className="testemunho-person">Alberto</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Testemunhos>
                <Mapa id="mapa">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d906.0144991743765!2d-53.7435925!3d-24.7248891!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f3958f27470451%3A0x496d9d872e51ef28!2sR.%20Dom%20Pedro%20II%2C%202829%20-%20Centro%2C%20Toledo%20-%20PR%2C%2085902-010!5e0!3m2!1spt-BR!2sbr!4v1691030557398!5m2!1spt-BR!2sbr"
                        width="100%" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </Mapa>
                <Endereco>
                    <div className="content">
                        <div className="icons-content">
                            <HomeOutlined className="icon" />
                            <p className="title">
                                Endereço
                            </p>
                            <p className="subtitle">
                                Rua Dom Pedro II 2829 | Sala 03
                            </p>
                        </div>
                        <div className="icons-content">
                            <MobileOutlined style={{ fontSize: "3.8rem" }} className="icon" />
                            <p className="title">
                                Telefone
                            </p>
                            <p className="subtitle">
                                Celular: ( 45 ) 9 9942-5066
                            </p>
                        </div>
                        <div className="icons-content">
                            <MailOutlined className="icon" />
                            <p className="title">
                                Email
                            </p>
                            <p className="subtitle">
                                construtoravorago@gmail.com
                            </p>
                        </div>
                    </div>
                </Endereco>
                <Footer>
                    <div className="content">
                        <p className="copyright">
                            © Vorago 2023, All Rights Reserved. Powered By <a href="https://nirayuki.netlify.app/" target="_blank">Nirayuki</a>
                        </p>
                        <div className="social">
                            <FacebookOutlined />
                            <a className="insta-a" href="https://instagram.com/construtoravorago" target="_blank"><InstagramOutlined  /></a>
                        </div>
                    </div>
                </Footer>
                <FloatButton type="primary" icon={<WhatsAppOutlined />} href="https://api.whatsapp.com/send/?phone=45999425066&text&type=phone_number&app_absent=0" target="_blank" />
            </>
        </>
    )
}

export default Main;