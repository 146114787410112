import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    

    input:focus{
      outline: none;
    }

    textarea:focus{
      outline: none;
    }

  } 
`;
 
export default GlobalStyle;