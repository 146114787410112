import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { MenuOutlined, InstagramOutlined, FacebookOutlined } from '@ant-design/icons'

import logo from '../assets/voragoassets/LOGOS/LOGO PNG 2.png';

import { Header, HeaderMobile } from "../styles/defaultLayout";
import { Content } from "../styles/404/content";
import { Footer } from "../styles/footer";
import { Button } from "antd";


function NotFound() {
    const [width, setWidth] = useState(window.innerWidth);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Not Found";

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNavigate = () => {
        navigate("/");
    }

    return (
        <>
            {
                width <= 950 ? (
                    <HeaderMobile>
                        <div className="content">
                            <div className="logo">
                                <img src={logo} />
                            </div >
                            <MenuOutlined className="menu" onClick={() => setOpen(!open)} />
                        </div>
                        <AnimatePresence>
                            {open && (
                                <motion.div
                                    className="drawner"
                                    layout
                                    initial={{ height: 0, opacity: 0 }}
                                    animate={{ height: "200px", opacity: 1 }}
                                    exit={{ height: 0, opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                    onClick={() => setOpen(false)}
                                >
                                    <div className="container">
                                        <Link to="/">Sobre Nós</Link>
                                        <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                        <Link to="/obras">Obras</Link>
                                        <Link to="/contato">Contato</Link>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </HeaderMobile>
                )
                    : (
                        <Header>
                            <div className="content">
                                <div className="logo">
                                    <img src={logo} />
                                </div >
                                <div className="navigation">
                                    <Link to="/">Sobre Nós</Link>
                                    <Link to="/imoveis-a-venda">Imóveis a Venda</Link>
                                    <Link to="/obras">Obras</Link>
                                    <Link to="/contato">Contato</Link>
                                </div>
                            </div >
                        </Header >
                    )
            }
            <>
                <Content>
                    <div className="content">
                        A página não foi encontrada!
                        <div className="button">
                            <Button type="primary" onClick={() => handleNavigate()}>
                                Voltar para a tela inicial
                            </Button>
                        </div>
                    </div>

                </Content>
                <Footer>
                    <div className="content">
                        <p className="copyright">
                            © Vorago 2023, All Rights Reserved. Powered By <a href="https://nirayuki.netlify.app/" target="_blank">Nirayuki</a>
                        </p>
                        <div className="social">
                            <FacebookOutlined />
                            <InstagramOutlined />
                        </div>
                    </div>
                </Footer>
            </>
        </>
    )
}


export default NotFound;