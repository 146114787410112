import { useState } from 'react';

import ImageDetails from '../styles/components/ImageDetails';
import Skeleton from './Skeleton';

const Image = ({ data, width, height, src, style, className }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const onLoad = () => {
        setImageLoaded(true);
    }

    return (
        <ImageDetails $width={width} $height={height}>
            {!imageLoaded && <Skeleton style={{ position: 'absolute' }} width={width} height={height} />}
            <img style={{...style}} className={className} src={src ? src : data?.link} onLoad={onLoad} />
        </ImageDetails>
    );
}


export default Image;