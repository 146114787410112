import { styled } from "styled-components";

export const Footer = styled.footer`
    width: 100%;

    display: flex;
    justify-content: center;

    padding: 30px 0px;

    border-top: 1px solid #e5e5e5;

    a{
        color: #D4A73A;
        text-decoration: none;
    }

    a:hover{
        opacity: 0.7;
    }

    .insta-a{
        color: #999999;
    }

    .content{
        width: 70%;

        display: flex;
        justify-content: space-between;
    }

    .copyright{
        color: #999999;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
    }

    .social{
        color: #999999;
        display: flex;
        align-items: center;

        gap: 10px;
    }

    .social svg{
        cursor: pointer;
    }

    .social svg:hover{
        opacity: 0.7;
    }

    @media screen and (max-width: 700px){
        .content{
            flex-direction: column;
            gap: 30px;
        }
    }
`