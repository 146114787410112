import { styled } from "styled-components";


export const Content = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;

    .content{
        max-width: 1300px;
        width: 100%;
        padding: 20px;

        margin: 50px 0px;
        gap: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;

        
    }


    .title{
        font-size: 2.5rem;
        color: #222222;
    }

    .content-venda{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 20px;
        column-gap: 50px;

        svg{
            font-size: 2rem;
            color: #D4A73A;
        }

        .loading{
            margin: 0 auto;
        }
    }

    .nodata{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nodata img{
        width: 150px;
        opacity: 0.5;
    }

`