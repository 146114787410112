import foto1 from '../assets/fotos/1.png';
import foto2 from '../assets/fotos/2.png';
import foto3 from '../assets/fotos/3.png';
 
export const dataCategorias = [
    {
        src: foto1,
        filter: "construcao"
    },
    {
        src: foto2,
        filter: "predio"
    },
    {
        src: foto3,
        filter: "planejamento"
    },
]