import React from "react";
import { Button, Checkbox, Form, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { AdminDetails } from "../styles/dashboard/admin";
import logo from '../assets/voragoassets/LOGOS/LOGO PNG 3.png'
import { useAuth } from "../lib/authContext";


function Admin() {

    const { sign, error, isLoading, setError, setIsLoading } = useAuth();

    const onFinish = (values) => {
        setIsLoading(true);
        const newItem = {
            email: values.email.replace(/ /g, ''),
            senha: values.senha
        }
        sign(newItem);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <AdminDetails>
            <div className="container">
                <div className="logo">
                    <img src={logo} />
                </div>
                <div className="error">
                    {error ? <p>Login inválido!</p> : null}
                </div>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Porfavor preencha seu Email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Senha"
                        name="senha"
                        rules={[
                            {
                                required: true,
                                message: 'Porfavor preencha sua senha!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{
                            offset: 0,
                            span: 16,
                        }}
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 0,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            {isLoading ? <LoadingOutlined /> : "Entrar"}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </AdminDetails>
    )
}

export default Admin;