import React from "react";
import { Card } from "../styles/cardImoveisAVenda/cardImoveisAVenda";
import { useNavigate } from "react-router";
import Image from "./Image";


export const CardImoveisAVenda = ({ data, key, loading }) => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(`/imoveis-a-venda/${data.titulo.toLowerCase().replace(/\s/g, '-')}/${data.id}`);
        window.scrollTo(0, 0);
    }

    return (
        <Card key={key} onClick={() => handleRedirect()}>
            <div className="imagem">  
                <Image src={data.imagesUrl[0]} height="150px" width="100%"/> 
            </div>
            <div className="categoria-card">
                <p>{data.categoria}</p>
            </div>
            <div className="title-card">
                <p>{data.titulo}</p>
            </div>
            <div className="local">
                <p>TOLEDO/PR</p>
            </div>
            <div className="preco-card">
                <p>{Number(data.preco).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
            </div>
        </Card>
    )
}